import { Injectable, EventEmitter } from '@angular/core';

import { environment } from '../../environments/environment';
import { UtilsService } from './utils.service';
import { Subject, ReplaySubject } from 'rxjs';

import * as AssineJaParser from 'assine-ja-parser/parser';
import { EnderecoService } from '../modules/checkout/services/endereco.service';
import { ViabilityService } from './viability.service';
import { Router } from '@angular/router';
import { Address } from '../modules/checkout/models/address';
import { LoadingService } from '../shared/loading/loading.service';
import { FingerprintService } from './fingerprint.service';
import { BannerPromocionalService } from '../modules/monte-sua-combinacao/modals/banner-promocional/banner-promocional.service';
import { StoryBlockService } from './storyblock.service';

interface ShowCableCityListI {
  city: string,
  regionId: string
}

@Injectable({
  providedIn: 'root',
})
export class AssineJaParserService {
  constructor(
    public utils: UtilsService,
    public loader: LoadingService,
    public enderecoService: EnderecoService,
    private router: Router,
    public viabilityService: ViabilityService,
    public fingerprintService: FingerprintService,
    private bannerPromocionalService: BannerPromocionalService,
    public storyblockService: StoryBlockService
  ) { }

  static newAssineJa = new EventEmitter<object>();
  public assinaJa: AssineJaParser;
  public jsonDataCabo: string;
  public jsonDataSatelite: string;
  public jsonCity: string;
  public jsonUF: string;
  public jsonCableDefault: string = 'assine_v3_net_default.json';
  public jsonSatelliteDefault: string = 'assine_v3_claro_default_claro.json';

  public parser: any;
  public parserReady$: ReplaySubject<any> = new ReplaySubject<any>();
  public parserReady = false;
  public dimensionReadyToSend$: Subject<any> = new Subject<any>();
  public viabilityReady$: Subject<any> = new Subject<any>();
  public viabilityLoading$: Subject<any> = new Subject<any>();
  public checkParser = false;
  public accordionCable = false;
  public accordionSatellite = false;
  public accordionActive = 'cabo';
  public showCableCityList: ShowCableCityListI[];

  public hasInternet = false;
  public hasTv = false;
  public hasFone = false;
  public hasCel = false;
  public technologiesAvailable = [];

  public haveCable: boolean;

  cableTrueMock = {
    "gpon": false,
    "name": "Cable",
    "tv": true,
    "phone": true,
    "internet": true
  }

  cableExpansionCityMock = {
    "gpon": true,
    "name": "Cable",
    "tv": true,
    "phone": true,
    "internet": true
  }

  isClaro: boolean;
  isNet: boolean;
  catalog: string;

  public initData: any;

  checkViability(mudaCep?: boolean): boolean {
    const cep = this.utils.storageGetItem('cep');
    const number = this.utils.storageGetItem('number');
    const city = this.utils.storageGetItem('city');
    const uf = this.utils.storageGetItem('uf');

    if ((cep === '' || number === '') && (city === '' || uf === '')) {
      return false;
    }

    this.initData = {
      isAssinante: 1, // l = cliente || 1 = não cliente
      subscriber: false, // Number(initData.isAssinante) === 0
      dccRulesPerProducts: {}, //regra para somar o subtrair o dcc se true soma no boleto 
      customerDccOption: undefined,
      portability: false,
      portabilityMobile: false,
      dataFile: '',
      urlCheckViability: '',
    };

    const endereco = this.enderecoService.getEndereco();
    endereco.installationZipcode = cep;
    endereco.installationNumber = number;
    endereco.installationCity = city;
    endereco.installationState = uf;

    this.parserReady = false;
    this.loader.show({ type: "default" });

    this.fingerprintService.getFingerprint().subscribe(
      (fingerprint_: string) => {
        this.viabilityService.getNewViability(cep, number, city, uf, fingerprint_).subscribe(
          (retorno) => {
            this.storyblockService.getStoryBlokRegrasGerais().subscribe(
              () => {
                const { data: viability } = retorno;
                endereco.installationAddress  = viability.logradouro
                endereco.installationCity     = viability.cidade
                endereco.installationState    = viability.uf
                endereco.installationDistrict = viability.bairro
           
                if (!viability.citySegment) { 
                    viability.citySegment = "mercados_em_desenvolvimento";
                }
                let dccRules = this.storyblockService.getdccRulesPerCategory(viability.citySegment);
    
                if (typeof dccRules === "string") {
                  dccRules = JSON.parse(dccRules);
                }                   
    
                this.initData.dccRulesPerProducts = dccRules;
    
                let cableResponse = viability.technologies?.find(tech => tech.name.toLowerCase() === 'cable');
                let satelliteResponse = viability.technologies?.find(tech => tech.name.toLowerCase() === 'satellite');
    
                this.utils.storageRemoveItem('isGpon')
                this.utils.storageRemoveItem('internet')
                this.utils.storageRemoveItem('phone')
                this.utils.storageRemoveItem('fone')
                this.utils.storageRemoveItem('tv')
    
                if (
                  (
                    !!cableResponse &&
                    !cableResponse.installationViability &&
                    !cableResponse.tv &&
                    !cableResponse.fone &&
                    !cableResponse.internet
                  ) ||
                  (
                    !viability.technologies || viability.technologies.length === 0
                  )
                ) {
                  const satellite = viability.technologies?.filter(tech => tech.name.toLowerCase() === 'satellite') || [];
                  const satelliteMock = {
                    "gpon": false,
                    "name": "Satellite",
                    "tv": true,
                    "phone": true,
                    "internet": true
                  }
            
                  viability.technologies = satellite?.length > 0 ? satellite : [satelliteMock];
                  satelliteResponse = viability.technologies[0];
                  cableResponse = undefined;
                }
            
                const showCableCityStorage = sessionStorage.getItem('showCableCity');
                if (
                  showCableCityStorage === 'true' &&
                  !cableResponse
                ) {
                  const showCableCity = this.showCableCityListCheck(this.showCableCityList, viability.cidade);
                  if (!!showCableCity) {
                    Array.isArray(viability.technologies) ?
                      viability.technologies.push(this.cableTrueMock) :
                      viability.technologies = [this.cableTrueMock]
                    cableResponse = this.cableTrueMock;
                    viability.regionId = showCableCity.regionId;
                    sessionStorage.setItem('technology', 'hfc');
                    this.viabilityService.addressTechnology = 'hfc';
                  }
                }
            
                const showCableExpansionCityStorage = sessionStorage.getItem('showCableExpansionCity');
                if (
                  showCableExpansionCityStorage === 'true' &&
                  !!cableResponse &&
                  cableResponse.gpon === true
                ) {
                  cableResponse = this.cableExpansionCityMock;
                  const cableIndex = viability.technologies.findIndex(tech => tech.name.toLowerCase() === 'cable')
                  viability.technologies[cableIndex] = cableResponse
                }
        
                if (!!cableResponse) {
                  this.utils.storageSetItem('isGpon', cableResponse.gpon)
                  this.utils.storageSetItem('internet', cableResponse.internet)
                  this.utils.storageSetItem('phone', cableResponse.phone)
                  this.utils.storageSetItem('fone', cableResponse.phone)
                  this.utils.storageSetItem('tv', cableResponse.tv)
                } else {
                  this.utils.storageSetItem('isGpon', 'false')
        
                  if (!!satelliteResponse) {
                    this.utils.storageSetItem('internet', satelliteResponse.internet)
                    this.utils.storageSetItem('phone', satelliteResponse.phone)
                    this.utils.storageSetItem('fone', satelliteResponse.phone)
                    this.utils.storageSetItem('tv', satelliteResponse.tv)
                  }
                }
        
                if (retorno.statusCode !== 200) {
                  console.warn('erro no cep, não encontramos tecnologia');
                  this.loader.hide();
                  this.router.navigate(['/error-cep']);
                  return false;
                }
        
                const jsonMobile = viability.catalog.cable.split('.');
                jsonMobile[0] += '_mobile';
        
                this.jsonDataCabo = jsonMobile.join('.');
                this.jsonDataSatelite = viability.catalog.aerial;
                this.jsonCity = viability.cidade;
                this.jsonUF = viability.uf;
        
                this.setAddressFromViability(endereco, cep, number, viability);
                this.setTechnologyFromViability(viability);
        
                if (mudaCep === true) {
                  this.viabilityReady$.next(true);
                  this.viabilityLoading$.next();
                  this.loader.hide();
                } else {
                  this.viabilityReady$.next(false);
                }
                this.haveCable = !!this.technologiesAvailable.find(
                  (obj) => obj.name === 'Cable'
                );
                sessionStorage.setItem('citySegment', viability.citySegment);
              },
              (error) => {
                console.error(error);
                this.router.navigate(['/error-cep']);
              }
            )
          },
          (error) => {
            this.loader.hide();
            console.warn(error);
            if (error) {
              this.handleError(error, endereco, mudaCep)
            } else {
              this.router.navigate(['/error-cep']);
            }    
          }
        );
      },
      (error => {
        console.error(error)
      })
    )
  }

  handleError(error, endereco, mudaCep): void {
    const { error: viability } = error
    if (viability &&
        viability.errors &&
        (viability.errors.code === "404" ||
         viability.errors.code === "404-001")) {
      this.storyblockService.getStoryBlokRegrasGerais().subscribe(
        () => {
          this.jsonDataCabo = viability.catalog.cable
          this.jsonDataSatelite = viability.catalog.aerial;
          this.jsonCity = viability.cidade;
          this.jsonUF = viability.uf;
          viability.bairro = viability.bairro || "SB"
    
          if (!viability.citySegment || viability.citySegment === "") { 
            viability.citySegment = "mercados_em_desenvolvimento";
          }
          let dccRules = this.storyblockService.getdccRulesPerCategory(viability.citySegment);
    
          if (typeof dccRules === "string") {
            dccRules = JSON.parse(dccRules);
          }                   
    
          this.initData.dccRulesPerProducts = dccRules;
    
          const showCableCityStorage = sessionStorage.getItem('showCableCity');
          let showCableCity: any;
    
          if (showCableCityStorage === 'true') {
            showCableCity = this.showCableCityListCheck(this.showCableCityList, viability.cidade);
          }
    
          if (!!showCableCity) {
            viability.technologies = [this.cableTrueMock]
            viability.regionId = showCableCity.regionId;
          } else {
            viability.technologies = [
              {
                "gpon": false,
                "name": "Satellite",
                "tv": true,
                "phone": true,
                "internet": true
              }
            ]
            viability.regionId = "021"
          }
    
          this.utils.storageSetItem('isGpon', 'false')
          this.utils.storageSetItem('internet', 'true')
          this.utils.storageSetItem('phone', 'true')
          this.utils.storageSetItem('fone', 'true')
          this.utils.storageSetItem('tv', 'true')
    
          this.setAddressFromViability(endereco, viability.cep, viability.number, viability);
          this.setTechnologyFromViability(viability);
    
          if (mudaCep === true) {
            this.viabilityReady$.next(true);
            this.viabilityLoading$.next();
            this.loader.hide();
          } else {
            this.viabilityReady$.next(false);
          }
        },
        (error) => {
          console.error(error);
          this.router.navigate(['/error-cep']);
        }
      )

    } else {
      this.router.navigate(['/error-cep']);
    }
  }

  getParser() {    
    this.getDataParser();
    const t = this;
    this.initData.CROcity = this.jsonCity;
    this.assinaJa = new AssineJaParser(this.initData, function () {
      t.parser = this;
      t.checkParser = true;
      t.parserReady = !t.parserReady;
      t.parserReady$.next(t.parserReady);
      t.dimensionReadyToSend$.next(true);
    });
  }

  getCartLength(): number{
    const { selection } = this.parser?.cart ?? {};
    const cartLength = selection ? Object.keys(selection).length : 0;
    return cartLength;
  }

  getPeriodosOferta(products = [], parser: any, tipo: string) {
    products.forEach((product: any) => {
      const {
        preco,
        precoDe,
        ate = 0,
      } = product;
      const periodoOferta = ate && `por ${ate} ${ate === 1 ? 'mês' : 'meses'}`;
      product.individual = { preco, precoDe, periodoOferta };

      if (ate === 1) {
        product.periodoOferta = 'por 1 mês';
      } else if (ate > 1) {
        product.periodoOferta = `por ${ate} meses`;
      }
    });

    return products;
  }

  recursosIcons(produtos) {
    for (const key of Object.keys(produtos)) {
      if (produtos[key].recursos !== undefined) {
        for (const key2 of Object.keys(produtos[key].recursos)) {
          produtos[key].recursos[key2].icon = this.replaceCharacters(
            produtos[key].recursos[key2].nome.toLowerCase()
          );
        }
      }
    }

    return produtos;
  }

  replaceCharacters(text) {
    const from = "áàãâéêíóôõúç '",
      to = 'aaaaeeiooouc__';

    for (let x = 0; x < text.length; x++) {
      const idx = from.indexOf(text[x]);
      if (idx > -1) {
        text = text.replace(text[x], to[idx]);
      }
    }

    return text;
  }

  getDetalhesInternet(produtos: any, tipo: string) {
    const recursosPadrao = this.parser.data.getRecursosPadrao(tipo);
    const recursosPadraoIds = this.parser.data.getRecursosPadraoIds(tipo);
    let detalhes = [];

    for (const key of Object.keys(produtos)) {
      detalhes = [];

      for (const key2 of Object.keys(recursosPadrao)) {
        const detalhe = {
          descritivo: recursosPadrao[key2],
          value: produtos[key].recursos_descritivos[recursosPadraoIds[key2]],
        };
        detalhes.push(detalhe);
        produtos[key].detalhes = detalhes;
      }
    }
  }

  getDataParser() {
    if (
      this.utils.storageGetItem('origem') === 'clarotv' ||
      this.accordionActive === 'satelite'
    ) {
      this.accordionActive = 'satelite';
      this.utils.setAccordionCatalog('clarotv');
      this.initData.dataFile = environment.dataPath + this.jsonDataSatelite;
    } else {
      this.accordionActive = 'cabo';
      this.utils.setAccordionCatalog('net');
      this.initData.dataFile = environment.dataPath + this.jsonDataCabo;
    }

    this.utils.setBrandTag(this.accordionActive);
  }

  getLeadCatalog(): string {
    if (!this.initData.dataFile) {
      const catalogStore = sessionStorage.getItem('catalog');

      if (!!catalogStore) {
        return catalogStore
      }else {
        console.error('Catálogo não encontrado')
        return ''
      }
    }

    return this.initData.dataFile === (environment.dataPath + this.jsonDataSatelite) ? 'clarotv' : 'net'
  }

  mostraComponentes() {
    const checkNewViability = this.viabilityService.isNewViability;
    const viabilityFilter = this.technologiesAvailable
      ? this.utils.storageGetItem('origem') === 'clarotv' ||
        this.accordionActive === 'satelite'
        ? this.technologiesAvailable.find((obj) => obj.name === 'Satellite')
        : this.technologiesAvailable.find((obj) => obj.name === 'Cable')
      : undefined;

    this.hasInternet =
      this.parser.data.hasinternet &&
      this.parser.data.filterProduct('internet').length >= 1 &&
      (!checkNewViability || (viabilityFilter && viabilityFilter.internet));
    this.hasTv =
      this.parser.data.hastv &&
      this.parser.data.filterProduct('tv').length >= 1 &&
      (!checkNewViability || (viabilityFilter && viabilityFilter.tv));
    this.hasFone =
      this.parser.data.hasfone &&
      this.parser.data.filterProduct('fone').length >= 1 &&
      (!checkNewViability ||
        (viabilityFilter && viabilityFilter.internet && viabilityFilter.phone));
    this.hasCel =
      this.parser.data.hascelular &&
      this.parser.data.filterProduct('celular').length >= 1;
  }

  private setAddressFromViability(
    endereco: Address,
    cep: any,
    number: any,
    viability: any
  ): void {
    if (viability.logradouro) {
      viability.logradouro = viability.logradouro.trim();

      endereco.installationType = this.enderecoService.getFormatedInstallationType(viability.logradouro);

      endereco.installationAddress = viability.logradouro.substr(
        viability.logradouro.indexOf(' ') + 1
      );
    } else {
      endereco.installationType = '';
      endereco.installationAddress = endereco.installationAddress || '';
      endereco.installationNumber = this.utils.storageGetItem('number');
    }
    endereco.installationDistrict = viability.bairro || endereco.installationDistrict;
    endereco.installationCity = viability.cidade || "";
    endereco.installationState = viability.uf || "";

    this.enderecoService.setEndereco(endereco);
  }

  public setTechnologyFromViability(viability: any): void {
    const hasViabilityNet = 
    viability.technologies.findIndex((obj) => obj.name === 'Cable') >= 0;
    const hasViabilityClaro = 
    viability.technologies.findIndex((obj) => obj.name === 'Satellite') >= 0;
    this.technologiesAvailable = viability.technologies;
    const technologyName = this.getTechnologyName(this.technologiesAvailable)
    this.accordionCable = hasViabilityNet;
    this.accordionSatellite = hasViabilityClaro;
    this.accordionActive =
      (hasViabilityNet && 'cabo') ||
      (hasViabilityClaro && 'satelite') ||
      'satelite';
    this.utils.storage.setItem(
      'canChangeInstallation',
      !hasViabilityClaro && !hasViabilityNet
    );

    if (viability.technologies.length === 0) {
      this.jsonDataSatelite = this.jsonSatelliteDefault;
      this.technologiesAvailable = [
        {
          gpon: false,
          internet: true,
          name: 'Satellite',
          phone: true,
          priority: false,
          tv: true,
        },
      ];
      this.accordionActive = 'satelite';
      this.accordionCable = false;
      this.accordionSatellite = true;
      this.utils.storage.setItem(
        'cepNoTech',
        this.utils.storage.getItem('cep')
      );
    }

    sessionStorage.setItem('nodeId', viability.cableNodeID)
    this.utils.storage.setItem('regionId', viability.regionId);
    this.bannerPromocionalService.setTechnology(technologyName);

    this.utils.setBrandTag(this.accordionActive);
  }

  getTechnologyName(techs: any): string {
    if(techs){
      const isSatellite = techs.find((tech: any) => tech.gpon === false && tech.name === 'Satellite');
      const isHFC = techs.find((tech: any) => tech.gpon === false && tech.name === 'Cable');
      const isGPON = techs.find((tech: any) => tech.gpon === true && tech.name === 'Cable');
      if (isGPON) { return 'gpon' }
      if (isHFC) { return 'hfc' }
      if (isSatellite) { return 'dth' }
    }
    return 'dth'
  }

  public getLocationTypeFromViability(): string {
    let locationType = 'hfc';
    this.technologiesAvailable.map((tech: { name: string; gpon: boolean }) => {
      const isWiredConnection = tech.name === 'Cable';
      const isGpon = tech.gpon === true;
      if (!isWiredConnection || !isGpon) {
        return locationType;
      }
      locationType = 'brownfield';
    });
    return locationType;
  }

  setCheckParser(value: boolean): void {
    this.checkParser = value;
  }

  showCableCityListCheck(list: ShowCableCityListI[], city: string): ShowCableCityListI {
    const newList = list.map(el => {
      return {
        city: this.normalizeCityName(el.city),
        regionId: el.regionId
      }
    })
    const newCity = this.normalizeCityName(city);

    return newList.find(el => el.city === newCity);
  }

  normalizeCityName(city: string): string {
    return city.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replace(/ /g, '').toLowerCase();
  }
}
