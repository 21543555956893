import { Injectable } from '@angular/core';

import { MobileClientValidationService, storageNamesEnum, stepsNamesEnum } from '../service/mobile-client-validation/mobile-client-validation.service';
import { MobileInfoService } from '../service/apis/mobile-info/mobile-info.service';
import { Router } from '@angular/router';
import { CroService } from 'src/app/services/cro.service';
import { LoadingService } from 'src/app/shared/loading/loading.service';
import { UtilsService } from 'src/app/services/utils.service';
import { MdnModalService } from 'src/app/shared/mondrian-components/mdn-modal/mdn-modal.service';
import { JaEClienteComponent } from 'src/app/modules/checkout/modals/ja-e-cliente/ja-e-cliente.component';
import { ConfiraOQueGanhouComponent } from '../../confira-o-que-ganhou/confira-o-que-ganhou.component';
import { CarrinhoService } from 'src/app/services/carrinho.service';
import { FingerprintService } from 'src/app/services/fingerprint.service';
import { IdentificationsService } from '../../../../services/identifications.service';

@Injectable({
  providedIn: 'root'
})
export class PushService {

  constructor(
    private router: Router,
    private cro: CroService,
    private loader: LoadingService,
    private utils: UtilsService,
    private carrinho: CarrinhoService,
    private modal: MdnModalService,
    private service: MobileClientValidationService,
    private mobileInfoService: MobileInfoService,
    public fingerprintService: FingerprintService,
    public identificationsService: IdentificationsService
  ) { }

  flowPush() {
    sessionStorage.removeItem(storageNamesEnum.MOBILE_INFO_RETURN)
    sessionStorage.setItem(storageNamesEnum.CLIENTE_CLARO_MOVEL, 'false')
    sessionStorage.setItem(storageNamesEnum.MODAL_CONFIRA_O_QUE_GANHOU_SHOWED, 'false')

    this.service.deleteUpgradeDePara();
    this.service.setIsClienteMovel(false);
    this.service.setMatch(false);

    const cpf    = this.service.getCpf();
    const msisdn = this.service.getMsisdn();
    const code   = this.service.getCode();
    const token  = this.service.getToken();

    this.getNewCustomerMobileInfo(cpf, msisdn, code, token)
  }

  getNewCustomerMobileInfo(cpf: string, msisdn: string, code: string, token: string) {

    this.mobileInfoService.getNewCustomerMobileInfo(cpf, msisdn, code, token).subscribe(
      resp => {
        const result: {isClienteMovel: boolean, match: boolean} = this.resolveClientMobileInfo(resp.data);

        const isClienteMovel = result.isClienteMovel;
        const match          = result.match;

        if (isClienteMovel) {
          this.service.setMatch(match);
          this.service.isClienteMovelChanged$.next(true);
    
          this.loader.show({
            type: "default",
            title: "Aguarde...",
            description: "Verificando se você possui um plano de residencial..."
          });

          this.resolveBaseCustumer(cpf);

        } else {
          this.service.setIsClienteMovel(false);
          this.service.setMatch(false);
          this.service.isClienteMovelChanged$.next(false);
          this.service.changeStep$.next(stepsNamesEnum.NOT_MOBILE_CUSTOMER)
        }
      },
      error => {
        this.resolveClientMobileInfoError(error);
      }
    )
  }

  resolveClientMobileInfo(data: any) {
    this.service.setCustomerPlanData(data);
    this.service.setCustumerName(data.customerName);

    const situation       = data.situation;
    const custumerType    = data.customerType;
    const dependents      = data.dependents;
    const discountValue   = data.promotionDiscountPlanValue;
    const planType        = data.planType;
    const isClienteMovel_ = situation === 'ATIVO'
    
    this.service.setIsClienteMovel(isClienteMovel_);

    const errors = this.getBussinessErrorsMobileInfo(situation, custumerType, dependents, discountValue, planType);
    errors.forEach(error => {
      this.cro.setError(this.router.url, error);
    });

    return {
      isClienteMovel: isClienteMovel_,
      match: isClienteMovel_ ? errors.length === 0 : false
    }
  }

  getBussinessErrorsMobileInfo(situation: string, custumerType: string, dependents: any, discountValue: number, planType: string): string[] {
    const errors = [];

    if (
      situation !== 'ATIVO' && custumerType !== 'owner' ||
      situation !== 'ATIVO' && custumerType === 'owner'
    ) {
      errors.push('não-titular');

    } else if (situation === 'ATIVO' && custumerType === 'owner') {

      if (dependents?.length > 0) {
        errors.push('possui-dependentes');
      }

      if (!['POS_PAGO', 'CONTROLE'].includes(planType)) {
        errors.push('plano-diferente-de-pos-ou-controle');
      }

      if (!!discountValue && discountValue > 0) {
        errors.push('plano-atual-possui-desconto');
      }
    }

    return errors;
  }

  resolveClientMobileInfoError(error: any): any {
    const err = error.error;
    const statusCode: number = err.statusCode;
    const errorCode: string = err.error.code;

    if (statusCode === 400 && (errorCode === '400' || errorCode === '400-002')) { // Token Expirado
      this.cro.setErrorApi(this.router.url, 'token-expirado');
      this.service.setCodeError$.next({ code_expired: true })
    } else if (statusCode === 404 && errorCode === '404') { // Token Inválido
      this.cro.setErrorApi(this.router.url, 'token-invalido');
      this.service.changeStep$.next(stepsNamesEnum.API_ERROR)
    } else if (statusCode === 406 && errorCode === '406') { // Code inválido
      this.cro.setErrorApi(this.router.url, 'code-invalido');
      this.service.setCodeError$.next({ code_does_not_match: true })
    } else if ( // Não é cliente Claro Movel
      (statusCode === 422 && errorCode === '422') ||
      (statusCode === 404 && errorCode === '404-001')
    ) {
      this.cro.setErrorApi(this.router.url, 'nao-cliente-claro:movel');
      this.service.changeStep$.next(stepsNamesEnum.NOT_MOBILE_CUSTOMER)
    } else if (errorCode === '400-004') { // tem 2 celular com o mesmo cpf
      this.cro.setError(this.router.url, 'mais-de-um-celular-no-cpf');
      this.service.changeStep$.next(stepsNamesEnum.NOT_MOBILE_CUSTOMER)
    } else if (errorCode === '400-005') { // erro na validação do sms
      this.cro.setErrorApi(this.router.url, 'erro-validacao-sms');
      this.service.changeStep$.next(stepsNamesEnum.API_ERROR)
    } else if (errorCode === '400-006') { // número de celular não é o principal
      this.cro.setError(this.router.url, 'celular-nao-principal');
      this.service.changeStep$.next(stepsNamesEnum.NOT_MOBILE_CUSTOMER)
    } else { // Qualquer outro erro
      this.cro.setErrorApi(this.router.url, 'default');
      console.error(err);
      this.service.changeStep$.next(stepsNamesEnum.API_ERROR)
    }
  }

  resolveBaseCustumer(cpf: string) {
    this.fingerprintService.getFingerprint().subscribe(
      (fingerprint_: string) => {
        this.identificationsService.getResidentialInfo(cpf, fingerprint_).subscribe(
            (customerResp) => {
              const userStatus = customerResp.data.status;
              const match = this.service.getMatch();
              this.service.setUsuarioDaBase(userStatus)
              this.closeModalWithTag();
  
              if (userStatus === 'NAO ENCONTRADO') {
  
                if (match) {
                  this.findAndUpdateMobilePlan();
                } else {
                  this.service.deleteUpgradeDePara();;
                  this.carrinho.updatedMobileCustomer = false;
                  this.service.openBanner();
                }
  
              } else {
                if (match) {
                  this.findAndUpdateMobilePlan();
                }
  
                this.loader.hide();
                this.service.closeDialog$.next();
                this.abrirDialogClienteConectadoMobileClient();
              }
  
            },
            (error) => {
              this.service.changeStep$.next(stepsNamesEnum.API_ERROR)
            }
          )
        },
        (error) => {
          this.service.changeStep$.next(stepsNamesEnum.API_ERROR)
        }
      );
  }

  closeModalWithTag(): void {
    this.cro.clickButtonModal(this.service.croOrigin, 'fechar');
    this.service.closeDialog$.next()
    this.loader.hide();
  }

  abrirDialogClienteConectadoMobileClient(): void {
    this.cro.openModal(`ja-e-cliente`);
    const modalConfig = {
      data: {
        size: 'sm',
        titulo: 'Importante!',
        disableClose: true,
        origin: 'cliente-movel',
        flow: 'default',
        modalAction: '',
        type: 'ja-e-cliente'
      }
    };
    this.modal.open(JaEClienteComponent, modalConfig);
  }

  findAndUpdateMobilePlan(): void {
    this.service.getListUpdatedPlans().subscribe(
      (resp) => {
        const planId = this.service.getCustomerPlanData().planId
        const newPlan = resp.find(el => el.de === Number(planId));

        if (newPlan) {
          this.service.setUpgradeDePara(newPlan)
          this.cro.setSuccess(this.router.url, `cliente-movel-match-success-${planId}-${newPlan.para}`);

          const cart = this.carrinho.getCart();
          const tvOrInternetInCart = cart.selection && (cart.selection?.tv || cart.selection?.internet);
          const newId = newPlan && newPlan.para;

          if (tvOrInternetInCart && !!newId) {
            this.service.setModalConfiraOQueGanhouShowed(true)

            this.carrinho.comprarProduto({
              id: newId,
              tipo: 'celular',
              cookie: true,
              showAlert: true,
            });

            this.carrinho.updatedMobileCustomer = true;

            if (this.service.getUsuarioDaBase() === 'NAO ENCONTRADO') {
              const mobile = this.carrinho.getCart()?.selection?.celular;

              this.modal.open(ConfiraOQueGanhouComponent, {
                data: {
                  size: 'lg',
                  titulo: 'Identificação do cliente',
                  sunWaterMark: true,
                  planName: mobile?.nome || '',
                  planValue: mobile?.preco || '',
                },
              });
            }

          } else {
            this.carrinho.updatedMobileCustomer = false;
            if (this.service.getUsuarioDaBase() === 'NAO ENCONTRADO') {
              this.service.closeDialog$.next()
              this.service.openBanner();
            }
          }
        } else {
          this.cro.setError(this.router.url, `plano-atual-nao-identificado-${planId}`);
          this.service.deleteUpgradeDePara();
          this.carrinho.updatedMobileCustomer = false;
          if (this.service.getUsuarioDaBase() === 'NAO ENCONTRADO') {
            this.service.closeDialog$.next()
            this.service.openBanner();
          }
        }
      }, (error) => {
        console.error(error);
      }
    );
  }


  
}
