import { Injectable } from '@angular/core';
import { UtilsService } from './utils.service';

interface Data {
  event?: string;
  eventCategory?: string;
  eventAction?: string;
  eventLabel?: string;
  nonInteraction?: string;
}

@Injectable({
  providedIn: 'root',
})
export class CroService {
  public mediaChannel = undefined;
  public navigation: string = 'home';
  public pageName: string = '/';
  private sendOnlyTime: boolean = true;
  public getGtmCategory = 'planos-claro-res';

  constructor(private utils: UtilsService) {}

  postDataLayer(data: any) {
    const obj: any = window;
    obj.dataLayer = obj.dataLayer || [];
    obj.dataLayer.push(data);
  }

  globalDimension(itensCart?: string, local?: string, locationType?: string, totalCart?: number) {
    this.postDataLayer({
      event:'global',
      dimension22: this.mediaChannel,
      dimension23: local || this.navigation,
      dimension24: itensCart,
      dimension58: locationType || undefined,
      'checkout-total': totalCart,
    });
  }

  accordionChange() {
    const data: any = {
      event: 'virtual-pageview',
      page: 'monte-sua-combinacao',
      dimension23: this.utils.getBrandTag(),
    };
    this.postDataLayer(data);
  }

  navegacaoAssine(pageName?: string) {
    const data: any = {
      event: 'virtual-pageview',
      page: pageName || this.pageName,
    };
    this.postDataLayer(data);
  }

  montaProdutoDetalhe(obj: any, tipo: string, position?: number) {
    const croObj = {
      name: this.utils.convertToSanitizer(obj.nome), // Nome ou ID do produto é obrigatório
      id: obj.id,
      price: this.utils.formataDinheiroCro(obj.preco.toString()),
      brand: tipo === 'celular' ? 'claro-movel' : this.utils.getBrandTag(),
      category: 'planos/' + tipo,
    };
    const list = 'planos-' + tipo;

    this.productDetail(croObj, list);
  }

  monitoramentoAPI(ec: string, ea: string, el: string) {
    const data: any = {
      event: 'event',
      eventCategory: ec,
      eventAction: ea,
      eventLabel: el,
    };

    this.postDataLayer(data);
  }

  genericGtm(eventAction: string, eventLabel: string, eventCategory?: string) {

    let ec = eventCategory ?`${this.getGtmCategory}:${eventCategory}` :this.getGtmCategory;
    const data: any = {
      event: 'event',
      eventAction: eventAction,
      eventLabel: eventLabel,
      eventCategory: ec,
    };

    this.postDataLayer(data);
  }


  focusPagamento(campo: string): void {
    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:checkout`,
      eventAction: 'interacao:campo',
      eventLabel: campo,
    };

    this.postDataLayer(data);
  }

  focusCep(campo: string): void {
    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:cep`,
      eventAction: 'interacao:campo',
      eventLabel: campo,
    };

    this.postDataLayer(data);
  }

  focusModal(campo: string, origin = 'claro'): void {
    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:modal-${origin}`,
      eventAction: `interacao:modal:${origin}`,
      eventLabel: `preencheu:${campo}`,
    };
    this.postDataLayer(data);
  }

  focusInput(campo: string, origin?: string, tipo?: string): void {
    tipo = tipo ? tipo : 'campo'
    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:${origin}`,
      eventAction: 'interacao:'+tipo,
      eventLabel: campo,
    };
    this.postDataLayer(data);

  }
  focusInputConfig(campo: string, origin?: string, tipo?: string): void {
    tipo = tipo ? tipo : 'campo'
    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:${origin}`,
      eventAction: 'interacao-campo:'+tipo,
      eventLabel: campo,
    };
    this.postDataLayer(data);

  }

  ownerShipReturns(result: boolean, titular: boolean) {
    const result_ = result ? 'celular-existe' : 'celular-nao-existe';
    const titular_ = titular ? 'titular' : 'dependente';

    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:checkout-personalizacao-movel`,
      eventAction: 'callback:api-ownerships-celular-existente',
      eventLabel: `${result_}-${titular_}`,
    };

    this.postDataLayer(data);
  }

  preenchimentoCampoPagamento(campo: string): void {
    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:checkout`,
      eventAction: 'preenchimento:campo',
      eventLabel: campo,
    };

    this.postDataLayer(data);
  }

  dimensaoPaginas() {
    const data = {
      dimension7: this.utils.getBrandTag(),
    };

    this.postDataLayer(data);
  }

  // Ao clicar em baner promocional
  bannerClick(name: string) {
    const nameBanner = '[[' + name + ']]';

    const data = {
      event: 'promotionClick',
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'clique:promocao',
      eventLabel: this.getGtmCategory,
      ecommerce: {
        promoClick: {
          promotions: [
            {
              name: nameBanner, // Nome do banner é obrigatório
            },
          ],
        },
      },
    };

    this.postDataLayer(data);
  }

  // Ao listar produtos na vitrine
  productImpression(obj: any) {
    let data: any;

    data = {
      event: 'productImpression',
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'productImpression',
      eventLabel: this.getGtmCategory,
      ecommerce: {
        impressions: obj,
      },
    };

    if (this.getSendOnlyTime()) {
      this.postDataLayer(data);
    }
  }

  // Ao abrir modais com detalhes dos produtos
  productDetail(obj: any, list?: string) {
    const data = {
      event: 'productDetail',
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'productDetail',
      eventLabel: this.getGtmCategory,
      ecommerce: {
        detail: {
          actionField: { list: list },
          products: [obj],
        },
      },
    };
    this.postDataLayer(data);
  }

  // Adicionar produto no carrinho
  AddToCart(obj: any) {
    if (obj !== undefined) {
      const data = {
        event: 'addToCart',
        eventCategory: 'enhanced-ecommerce',
        eventAction: 'addToCart',
        eventLabel: this.getGtmCategory,
        ecommerce: {
          add: {
            products: obj,
          },
        },
      };
      this.postDataLayer(data);
    }
  }

  // Remover produto do carrinho
  RemoveFromCart(obj: any) {
    const data = {
      event: 'removeFromCart',
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'removeFromCart',
      eventLabel: this.getGtmCategory,
      ecommerce: {
        remove: {
          products: [obj],
        },
      },
    };
    this.postDataLayer(data);
  }

  // À cada etapa da página
  Checkout(obj: any, step: any, itens: any) {
    const data = {
      event: 'checkout',
      eventCategory: 'enhanced-ecommerce',
      eventAction: `checkout-etapa:${step.eventAction}`,
      eventLabel: `${this.getGtmCategory}:${this.utils.getBrandTag()}`,
      ecommerce: {
        checkout: {
          actionField: { step: step.actionField },
          products: obj,
        },
      },
    };
    this.postDataLayer(data);
  }

  // Deve carregar apenas uma vez ao chegar na página de sucesso
  Purchase(id: any, itens: any, revenue: any, tax: any, txt: any) {
    const data = {
      event: 'purchase',
      eventCategory: 'enhanced-ecommerce',
      eventAction: 'purchase',
      eventLabel: `${this.getGtmCategory}:${this.utils.getBrandTag()}`,
      noInteraction: '1',
      ecommerce: {
        purchase: {
          actionField: {
            id: id,
            affiliation: this.getGtmCategory,
            revenue: revenue,
            tax: tax > 0 ? tax : undefined,
          },
          products: itens,
        },
      },
    };
    this.postDataLayer(data);
  }

  fieldModal(fieldName: string): void {
    const data: any = {
      event: 'event',
      eventCategory: this.getGtmCategory + ':modal-' + fieldName,
      eventAction: 'interacao:campo',
      eventLabel: fieldName,
    };

    this.postDataLayer(data);
  }

  clickButton(origin: string, name: string, label: string): void {
    const data: Data = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:${origin}`,
      eventAction: 'clique:botao',
      eventLabel: `${name}:${label}`,
    };

    this.postDataLayer(data);
  }

  openModal(modalName: string, produto?: string, pageName?: string, technology?: string): void {

    produto = produto ? ':' + produto : '';
    technology = technology ? `:${technology}` : '';
    pageName = pageName ? ':' + pageName : '';

    const data: Data = {
      event: 'event',
      eventCategory: this.getGtmCategory + pageName + ':modal-' + modalName,
      eventAction: 'modal:abriu',
      eventLabel: modalName + produto + technology,
      nonInteraction: 'True',
    };

    this.postDataLayer(data);
  }

  clickButtonModal(origin: string, action: string, event?: string): void {
    event = event || `clique:botao:modal:${origin}`;
    const data: Data = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:modal-${origin}`,
      eventAction: event,
      eventLabel: action
    };

    this.postDataLayer(data);
  }

  ChangeInputModal(label: string, modal?: string): void {
    const data: Data = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:modal-${modal}`,
      eventAction: `interacao:modal:${modal}`,
      eventLabel: `preencheu:${label}`,
    };
    this.postDataLayer(data);
  }

  cliqueCampo(campo: string): void {
    const data: any = {
      event: 'event',
      eventCategory: this.getGtmCategory,
      eventAction: 'clique:botao',
      eventLabel: campo,
    };

    this.postDataLayer(data);
  }

  getSendOnlyTime(): boolean {
    return this.sendOnlyTime;
  }

  setSendOnlyTime(sendOnlyTime: boolean): void {
    this.sendOnlyTime = sendOnlyTime;
  }

  dimensionGlobalWithProducts(
    obj: any,
    local?: string,
    locationType?: string,
  ): void {
    try {
      const varSelection = obj;
      let valueSelectionCro = '';
      let valorCart = 0;
      for (const key in varSelection) {
        if (varSelection.hasOwnProperty(key) && key !== 'selecoes') {
          valueSelectionCro += valueSelectionCro !== '' ? '+' : '';
          valueSelectionCro += this.utils.convertToSanitizer(
            varSelection[key].nome
            );

            valorCart += parseFloat(varSelection[key].preco) /100
        }
      }

      this.globalDimension(String(valueSelectionCro), local, locationType, valorCart);
    } catch (error) {}
  }

  clickButtonNew(origin: string, label: string, name?: string, separator?: string, separator2?: string): void {

    name = name ? name : ''
    separator = separator ? separator : ''
    separator2 = separator2 ? separator2 : ''

    const data: Data = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}`+separator+`${origin}`,
      eventAction: 'clique:botao',
      eventLabel: `${name}`+separator2+`${label}`,
    };

    this.postDataLayer(data);
  }

  additionals(action: string, label: string): void {
    const data: Data = {
      event: 'event',
      eventCategory: 'planos-claro-res:jornada-adicionais',
      eventAction: action,
      eventLabel: label
    };

    this.postDataLayer(data);
  }

  setError(page: string, error: string): void {
    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:${page}`,
      eventAction: 'catch:error',
      eventLabel: error,
    };

    this.postDataLayer(data);
  }

  setErrorApi(page: string, error: string): void {
    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:${page}`,
      eventAction: 'catch:api-error',
      eventLabel: error,
    };

    this.postDataLayer(data);
  }

  setSuccess(page: string, label: string): void {
    const data: any = {
      event: 'event',
      eventCategory: `${this.getGtmCategory}:${page}`,
      eventAction: 'catch:success',
      eventLabel: label,
    };

    this.postDataLayer(data);
  }


  sanitizer(str: string): string {
    return str.toLowerCase().replace(/ /g, '-').normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
